import clsx from "clsx";
import { Show } from "solid-js";

import type { Component } from "solid-js";

import { presentationalUrl } from "./helpers.js";

import trpc from "./lib/trpc.js";
import useLinksStateContext from "./lib/linksstate.jsx";

import BootstrapIcon from "./BootstrapIcon.js";
import LinkSupplementalActions from "./LinkSupplementalActions.js";

import type { Link } from "../server/types.js";

const LinkComponent: Component<{ link: Link }> = (props) => {
  let el: HTMLLIElement | undefined;

  const { linksState } = useLinksStateContext();

  const setLinkReadState = async (targetState: boolean, event: Event) => {
    // This keeps the <article>'s onclick from firing and undoing this very action
    event.stopPropagation();

    if (targetState === true) {
      const updatedLinks = await trpc.app.links.markLinkRead.mutate(
        props.link.link_id
      );
      updatedLinks.forEach((link) => linksState.upsertLink(link));
    } else {
      // This branch doesn't muck with topDeleted because we need to be sure
      // that's only updated once the pubsub link state event arrives,
      // otherwise the UI flashes from the inconsistency of thinking the
      // link has a deleted_at and is also not the top deleted link.
      const updatedLink = await trpc.app.links.markLinkUnread.mutate(
        props.link.link_id
      );
      linksState.upsertLink(updatedLink);
    }
  };

  return (
    // We use the li directly here so that we can supply the ARIA label, and
    // can have one fewer level in the a11y tree (since the listitem level
    // is unavoidable)
    //
    // min-w-0 is necessary for ellipsis truncation of long URLs
    <li
      class={clsx(
        "border rounded-lg shadow-sm h-full border-gray-500 flex flex-col justify-between bg-white/90 min-w-0",
        props.link.deleted_at && "border-gray-500 border-8"
      )}
      style="contain: paint style layout;"
      data-cy="link"
      data-url={props.link.url}
      data-linkid={props.link.link_id}
      ref={el}
      aria-label={props.link.title ?? "Untitled Page"}
    >
      <a
        href={props.link.url}
        class="link block p-2 hover:bg-gray-200 transition-colors rounded-t-lg h-full"
        rel="noopener noreferrer external"
        target="_blank"
        aria-label="View item"
        title={props.link.title ?? "Untitled page"}
        onclick={setLinkReadState.bind(null, true)}
        data-cy="link-destination"
      >
        {props.link.deleted_at && (
          <span
            class="text-lg mr-2 float-left text-gray-500"
            data-cy="deleted-indicator"
          >
            <BootstrapIcon
              icon="clock-history"
              alt="This link is in the recycle bin"
            />
          </span>
        )}

        <header
          class="text-lg break-words"
          data-cy="title"
          aria-label="Article title"
        >
          {props.link.title?.slice(0, 140) ?? "Untitled Page"}
        </header>
        <p
          title={presentationalUrl(props.link.url)}
          class="block text-xs italic text-gray-600 underline whitespace-nowrap overflow-hidden text-ellipsis break-words"
          aria-label="Article URL"
          data-cy="presentational-url"
        >
          {presentationalUrl(props.link.url)}
        </p>
        {/* Line clamp of 4 is arbitrary. Either Microlink or origin sites
            seem to truncate arbitrary, variable amounts of text. */}
        <p
          class="text-sm text-gray-800 italic break-words line-clamp-4"
          aria-label="Article preview"
        >
          {props.link.description}
        </p>
      </a>

      {/* items-center instead of items-stretch because the turtle image can't stretch */}
      <div class="w-full border-t border-gray-300 flex justify-between items-center">
        <LinkSupplementalActions link={props.link} />

        <Show
          when={!props.link.deleted_at}
          fallback={
            <button
              class="px-4 text-xl text-gray-500 hover:bg-gray-200 transition-colors py-2"
              data-cy="undelete-link"
              title="Mark as unread"
              type="button"
              onclick={setLinkReadState.bind(null, false)}
              role="button"
              aria-pressed={false}
            >
              <BootstrapIcon icon="check-square" />
            </button>
          }
        >
          <button
            class="px-4 text-xl text-gray-500 hover:bg-gray-200 transition-colors py-2"
            data-cy="delete-link"
            title="Mark as read"
            type="button"
            onclick={setLinkReadState.bind(null, true)}
            role="button"
            aria-pressed={true}
          >
            <BootstrapIcon icon="square" />
          </button>
        </Show>
      </div>
    </li>
  );
};
export default LinkComponent;
