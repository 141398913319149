import { render } from "solid-js/web";
//import { init as initSentry } from "@sentry/browser";
import {
  BrowserClient,
  defaultStackParser,
  getCurrentHub,
  makeFetchTransport,
  Breadcrumbs,
  Dedupe,
  GlobalHandlers,
  TryCatch,
  HttpContext,
} from "@sentry/browser";
import Honeybadger from "@honeybadger-io/js";

import "./style.css";

import App from "./app/App";

declare global {
  interface Window {
    cspNonce: string;
    sentryDsn?: string;
    honeybadgerKey?: string;
  }
}

localStorage.debug = "readstufflater:*";

if (window.honeybadgerKey) {
  console.log("Configuring Honeybadger");
  Honeybadger.configure({
    apiKey: window.honeybadgerKey,
    environment: "production",
  });
}

if (window.sentryDsn) {
  /*
  initSentry({
    dsn: window.sentryDsn,
  });
  */
  // Initializing Sentry this verbosely enables a teensy bit better tree shaking
  const sentryClient = new BrowserClient({
    // all options you normally pass to Sentry.init
    dsn: window.sentryDsn,

    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    // Only the integrations listed here will be used
    integrations: [
      new Breadcrumbs(),
      new GlobalHandlers(),
      new Dedupe(),
      new TryCatch(),
      new HttpContext(),
    ],
  });
  getCurrentHub().bindClient(sentryClient);
}

if ("serviceWorker" in navigator) {
  void navigator.serviceWorker.register("/service-worker.js").then(() => {
    console.log("Service Worker Registered");
  });
}

render(() => <App />, document.getElementById("app") as HTMLElement);
