import { Show } from "solid-js";

import type { Component } from "solid-js";

import trpc from "./lib/trpc.js";

import BootstrapIcon from "./BootstrapIcon.js";
import EmojiPickerButton from "./EmojiPicker.js";

import type { Link } from "../server/types.js";

const DefaultActions: Component<{ link: Link }> = (props) => {
  const setEmoji = async (emojiShortcode: string | null) => {
    await trpc.app.links.setEmojiOne.mutate({
      linkId: props.link.link_id,
      emoji: emojiShortcode,
    });
  };

  return (
    <div class="flex h-full">
      <EmojiPickerButton onchange={setEmoji} value={props.link.emoji} />
    </div>
  );
};

const DeletedLinkActions: Component<{ link: Link }> = (props) => {
  const hardDelete = async (event: Event) => {
    // We have to stop bubbling, otherwise the link's on-click handler will
    // *also* handle this event
    event.stopPropagation();

    await trpc.app.links.hardDeleteOne.mutate(props.link.link_id);
  };

  return (
    <button
      title="Permanently delete this link"
      type="button"
      class="text-xl text-gray-500 hover:bg-gray-200 transition-colors px-4 h-full"
      onclick={hardDelete}
      data-cy="hard-delete"
    >
      <BootstrapIcon icon="trash3" />
    </button>
  );
};

const LinkSupplementalActions: Component<{
  link: Link;
}> = (props) => {
  return (
    <Show
      when={!props.link.deleted_at}
      fallback={<DeletedLinkActions link={props.link} />}
    >
      <DefaultActions link={props.link} />
    </Show>
  );
};

export default LinkSupplementalActions;
