import dayjs from "dayjs";
import { onCleanup, createSignal, createMemo } from "solid-js";

import authnState from "./authnstate.js";

/**
 * This returns how much time is remaining. The string will be minimal (e.g.,
 * "7 days"), and won't contain any prefix or suffix.
 */
// TODO: We'd like this to only register one interval globally. No need for
// every place this is used to keep its own timer. But then again, that's a
// pretty nit-picky thing, so low priority.
export const getAccessExpiryHours = () => {
  const [referenceTime, setReferenceTime] = createSignal(new Date());
  const expiry = createMemo((): string | null => {
    const expiresAt = authnState.session?.access_expires_at;
    // Either the user is paid-up, or this was somehow (inappropriately!) used
    // in a place where the user wasn't logged in
    if (!expiresAt) return null;

    const isExpired = dayjs(expiresAt).isBefore(dayjs(referenceTime()));

    if (isExpired) return null;

    const timeRemainingHuman = dayjs(expiresAt)
      .fromNow(true)
      .replace(/^(a|an) (few )?/, "1 ");
    return timeRemainingHuman;
  });

  if (authnState.session?.access_expires_at) {
    alignIntervalToDate(authnState.session.access_expires_at, () =>
      setReferenceTime(new Date())
    );
  }

  return expiry;
};

/**
 * If the supplied date is 13:45, we'll run the function immediately, then run
 * it periodically. Initially, every hour on the :45, then more frequently if
 * there's only a small amount of time remaining.
 */
const alignIntervalToDate = (date: Date, fn: () => void) => {
  // Align the updates for the countdown to exactly how many minutes are
  // left, so the user gets the correct readout
  const timeRemainingMinutes = dayjs(date).diff(dayjs(), "minutes");
  const minutesUntilRoundNumber =
    timeRemainingMinutes - 60 * Math.floor(timeRemainingMinutes / 60);
  const timeoutMinutes =
    minutesUntilRoundNumber === 0 ? 60 : minutesUntilRoundNumber;

  // Periodically refresh the countdown so the user has an accurate sense of
  // their time remaining. We want our interval to align with the expiry time,
  // so delay setting up the interval
  let interval: NodeJS.Timeout;
  const timeout = setTimeout(() => {
    fn();

    // I don't think it's important to run this check slowly, except that
    // Cypress ticks time forward so fast that this can lock up the page. Maybe
    // that means we use cy.clock() without using cy.tick() ?
    //const period = timeRemainingMinutes < 60 * 12 ? 60_000 : 3_600_000;
    const period = timeRemainingMinutes < 60 * 12 ? 600_000 : 600_000;
    interval = setInterval(() => {
      fn();
    }, period);
  }, timeoutMinutes * 60_000);

  onCleanup(() => {
    clearTimeout(timeout);
    clearInterval(interval);
  });

  fn();
};
