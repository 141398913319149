import autoAnimate from "@formkit/auto-animate";
import clsx from "clsx";
import { createSignal, createMemo, createEffect, For, Show } from "solid-js";
import { useSearchParams } from "@solidjs/router";

import type { AnimationController } from "@formkit/auto-animate";
import type { Component } from "solid-js";

import { presentationalUrl, retainScrollPosition } from "./helpers.js";
import { classNameForDomain } from "./lib/linkGroupStyles.js";

import Favicon from "./Favicon.jsx";
import LinkComponent from "./Link.jsx";

import type { Link, Domain } from "../server/types.js";

/**
 * Removes the '| Hacker News' from page titles
 *
 * Notes:
 * - Reddit prefixes titles with the subreddit name and '-'
 * - Github prefixes titles with the repo name and ':'
 * - brr.fyi uses an endash
 */
const findCommonPrefix = (titles: string[]): string | null => {
  // We don't want to think the only title is a common prefix in its entirety
  if (titles.length <= 1) return null;
  // Display the original title if somehow every link in the group has the same title
  if (titles.every((title) => title === titles[0])) return null;

  // We need the capture group and the /g to get the separator into the result,
  // so that we correctly consider the separator to be part of the common prefix
  const chunkedTitles = titles.map((title) => title.split(/\s([-|•|–])\s/g));

  const shortestChunkSet = Math.min(
    ...chunkedTitles.map((chunk) => chunk.length)
  );
  // If the shortest chunk has length 0, it means we didn't find any separators
  // in the title. If it has length 1, that means we have something like a bunch
  // of titles "Foo | Hacker News", plus a title "Hacker News" (i.e., some title
  // is a substring of the other links' suffix).
  if (shortestChunkSet < 2) return null;

  let biggestMatchingChunk = null;
  for (let i = 0; i < shortestChunkSet; i++) {
    if (
      chunkedTitles.every(
        (chunkedTitle) => chunkedTitle[i] === chunkedTitles[0][i]
      )
    ) {
      biggestMatchingChunk = i;
    } else {
      break;
    }
  }
  // None of the chunks were shared across all titles
  if (biggestMatchingChunk === null) return null;
  // This won't work right if a title somehow uses non-space word separators.
  // Not sure if that's possible.
  //
  // We need +1 to be inclusive of the highest matching chunk number
  return chunkedTitles[0].slice(0, biggestMatchingChunk + 1).join(" ");
};

interface LinkGroupProps {
  domain: Domain;
  links: Link[];
}

const LinkGroup: Component<LinkGroupProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  let detailsEl: HTMLDetailsElement | undefined;
  let summaryEl: HTMLDivElement | undefined;
  let ulEl: HTMLUListElement | undefined;

  const [animationController, setAnimationController] =
    createSignal<AnimationController | null>(null);

  const open = () => {
    return searchParams.openLinkGroup === props.domain.domain;
  };

  const linkCountText = createMemo(
    () => `${props.links.length} ${props.links.length === 1 ? "link" : "links"}`
  );

  const d = new Date().getTime();
  console.log("Creating link group");
  const links = createMemo(() => props.links.slice(0, 50));

  const stripPrefixSuffix = createMemo(() => {
    //console.log("Slicing links", props.domain.domain);
    if (new Date().getTime() >= d + 1_000) {
      //debugger;
    }
    const titles = links()
      .map(({ title }) => title)
      .filter((title): title is string => Boolean(title));
    const commonPrefix = findCommonPrefix(titles);
    const commonSuffix = findCommonPrefix(
      titles.map((title) => title.split("").reverse().join(""))
    )
      ?.split("")
      ?.reverse()
      ?.join("");

    return (link: Link): Link => {
      let title = link.title;
      if (commonPrefix) {
        title = link.title?.substring(commonPrefix.length)?.trim() || null;
      }
      if (commonSuffix) {
        title = link.title
          ? link.title
              .substring(0, link.title.lastIndexOf(commonSuffix))
              ?.trim()
          : null;
      }
      return { ...link, title };
    };
  });
  // If we animate too many links at once it nukes performance enough that
  // the UI is just totally unusable. This threshold is arbitrary.
  //
  // TODO: This really doesn't do enough on mobile. After the Forgo perf fix
  // is in, reevaluate if this is the best option. Maybe we need to limit
  // the display to
  // N items per group instead?
  //
  // TODO: If we're going to apply this limit to fresh links, we DEFINITELY
  // need the 'load more' button.
  createEffect(() => {
    return;
    if (links.length <= 50) {
      if (!animationController()) {
        // If we run this synchronously it forces a layout recalculation
        // (layout thrashing) because it has to measure the styles right in
        // the middle of our DOM manipulation, which forces the browser to
        // reflow the DOM during the JS execution phase, then resume JS
        // execution.
        requestAnimationFrame(() =>
          setAnimationController(
            autoAnimate(ulEl!, {
              duration: 150,
            })
          )
        );
      } else if (!animationController()!.isEnabled()) {
        animationController()!.enable();
      }
    } else {
      animationController()?.disable();
    }
  });

  return (
    <details
      class={clsx("mb-4 last:mb-0", classNameForDomain(props.domain))}
      style="contain: paint style layout;"
      data-cy="link-group"
      data-domain={props.domain.domain}
      open={open()}
      ref={detailsEl}
      onclick={(e) => {
        // We use onclick instead of ontoggle because ontoggle fires *after*
        // the browser has handled the toggle, which means we can't
        // intercept & delay the element closing to make our animations
        // appear properly. So instead we take over the whole open/close process.
        e.preventDefault();
        // Make sure that clicking the details element background doesn't
        // toggle the element
        if (!summaryEl!.contains(e.target! as Node)) return;

        const newOpenState = !open() ? props.domain.domain : null;

        // If we close a long link group to open a shorter one further down
        // the page, collapsing the old group can offset the page content so
        // much that the new group isn't on-screen.
        if (newOpenState) {
          retainScrollPosition(detailsEl!);
        }
        // TODO: Because we've linked "draw the children" with "close the
        // <details>", we don't animate closing the <details> anymore.
        setSearchParams({ openLinkGroup: newOpenState });
      }}
      ontoggle={(): void => {
        return;
      }}
    >
      {/* z-10 to keep the sticky group header above the body instead of
      underneath it. overflow-hidden so rounding the corners clips children too.*/}
      {/* We have to be very deliberate about how the base color on the pattern
       gets applied, to avoid color bleed when used with border-radius. See the
      pattern module for more detail. */}
      <summary
        class="bg-white rounded-b-lg list-none top-0 mb-1 z-10 cursor-pointer max-w-full text-2xl text-gray-700 flex items-center"
        aria-label={`Links for ${presentationalUrl(props.domain.domain)}`}
        data-cy="header"
        ref={summaryEl}
      >
        {/* We have to implement our own carets because there's no way to get a
            summary child to be full-width, be a flex container, and also be on
            the same line as the browser's built-in caret.

            We have to force the character not *not* render as emoji, for Android:
            https://stackoverflow.com/questions/32915485/how-to-prevent-unicode-characters-from-rendering-as-emoji-in-html-from-javascrip

            Be careful here - Android loves to not render misc unicode symbols*/}
        <span class="mr-1 text-xs" style="font-family: math;">
          {open() ? "▼" : "▶"}
        </span>

        {/* This is separated from the <summary> so that the summary can
            have a white background, meaning when it stickies the link group
            body coloring doesn't peek out from around the top corners of the
            summary. */}
        <header class="from-gray-200 flex items-baseline p-2 rounded-lg flex-1">
          {/* Favicons are decorative, so hide them from screen readers */}
          <span class="self-center mr-2" aria-hidden="true">
            <Favicon domain={props.domain} />
          </span>
          <span
            data-cy="title"
            class="flex-1 whitespace-nowrap overflow-hidden text-ellipsis max-w-max"
          >
            {presentationalUrl(props.domain.domain)}
          </span>
          {/* select-none prevents this from being highlighted when the user
                  rapidly opens and closes the drop-down */}
          <span
            class="ml-2 text-sm text-gray-800 italic select-none"
            data-cy="links-count"
          >
            {linkCountText()}
          </span>
        </header>
      </summary>

      <ul
        class={clsx(
          // Only pad while open, otherwise the on-close animation looks
          // janky when the element collapses to be just the size of its
          // padding
          open() && "p-2 md:p-4",
          "rounded-lg",
          links().length === 0
            ? "flex justify-center"
            : "grid gap-2 md:gap-4 gap-y-2 grid-cols-[repeat(auto-fill,_minmax(min(100%,_370px),_1fr))] auto-rows-min"
        )}
        ref={ulEl}
        data-cy="link-group-body"
        aria-label={`Links (${links().length})`}
      >
        {/*
              Drawing the all of the elements slows down the DOM, even if the
              group is collapsed

              Testing the performance of always rendering these rather than doing it
              conditionally. Normally it'd nuke the DOM performance, but maybe CSS
              'contain' fixes that? And I don't know if the slowness of opening a
              group with 125+ items is Forgo render time or DOM updates.

              Do the open/close conditional *inside* the <ul> so we can
              correctly animate the <ul> adding / removing children.
            */}
        <Show when={open}>
          <For each={links()}>
            {(link) => <LinkComponent link={stripPrefixSuffix()(link)} />}
          </For>
        </Show>
      </ul>
    </details>
  );
};
export default LinkGroup;
