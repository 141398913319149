import { createSignal } from "solid-js";
import type { Component } from "solid-js";

import Dialog from "./Dialog";

import addLinkImg from "./images/add-link.png";

// Chrome offers branded assets here:
// https://developer.chrome.com/docs/webstore/branding/ However, the text gets
// very fuzzy when resized, and the styling is garbage next to Mozilla's
// official branding.
//
// So instead, take the Chrome Web Store logo from here
// https://upload.wikimedia.org/wikipedia/commons/c/c5/Google_Chrome_Web_Store_icon_2015.svg
// and recreate the same badge in HTML+CSS.
import chromeWebStore from "./images/chrome-web-store.svg";
// Same thing here. Mozilla's official badge,
// https://extensionworkshop.com/documentation/publish/promoting-your-extension/
// is a glaring blue box is way too attention-grabbing. So instead, recreate its
// style using the Firefox logo from here: https://commons.wikimedia.org/wiki/File:Firefox_logo,_2019.svg
import firefoxAddOn from "./images/firefox-logo.svg";

const Help: Component = () => {
  const [showHelp, setShowHelp] = createSignal(true);

  return (
    <>
      <Dialog
        open={showHelp()}
        onclose={() => setShowHelp(false)}
        topButton={
          <h1 class="text-xl font-semibold border-b-2 border-dashed border-gray-300">
            How to Use ReadStuffLater
          </h1>
        }
        class="max-w-md"
      >
        <p class="mt-4">
          Welcome! ReadStuffLater is the place for you to save stuff you want to
          come back to.
        </p>
        <p class="mt-4">
          Everything here is casual - there's no pressure. to get anything done.
          Just have fun! 🙂
        </p>

        <ol class="mt-4">
          <li>
            <header class="text-xl font-medium border-r-2 border-t-2 border-dotted border-gray-300 w-max pr-1 mb-2">
              1. Add a link
            </header>
            <p class="ml-2 flex flex-col">
              Click the 'Add Link' button and add a link you want to save for
              later
              <img
                class="w-64 self-center border-4 border-blue-200 rounded-md shadow-inner"
                src={addLinkImg}
              />
            </p>

            <p class="ml-2 mt-4">
              You can also use our browser extensions to save links while you're
              on other websites!
            </p>

            <p class="mt-2 ml-2 flex flex-wrap justify-center gap-x-8 gap-y-2">
              <a
                class="inline-flex items-center p-2 border border-gray-300 rounded-md w-max"
                href="https://chrome.google.com/webstore/detail/read-stuff-later/jmifjlabofcehgpccfhldofddenhigep"
              >
                <img
                  src={chromeWebStore}
                  alt="Download our extension from the Chrome Web Store"
                  class="h-12 inline"
                />
                <span class="ml-2 text-gray-600 font-medium">
                  <span class="block text-xs">Available in the</span>
                  <span class="block text-sm">Chrome Web Store</span>
                </span>
              </a>

              <a
                class="inline-flex items-center p-2 border border-gray-300 rounded-md w-max"
                href="https://addons.mozilla.org/en-US/firefox/addon/read-stuff-later/"
              >
                <img
                  src={firefoxAddOn}
                  alt="Download our Firefox add-on"
                  class="h-12 inline"
                />
                <span class="ml-2 text-gray-600 font-medium">
                  <span class="block text-xs">GET THE</span>
                  <span class="block text-sm">ADD-ON</span>
                </span>
              </a>
            </p>
          </li>
        </ol>
      </Dialog>
      <button onclick={() => setShowHelp(true)} class="underline">
        Help
      </button>
    </>
  );
};
export default Help;
