import { A, useNavigate } from "@solidjs/router";

import type { Component } from "solid-js";

import Button from "./Button.js";
import Pricing from "./Pricing.js";

import authnstate from "./lib/authnstate.js";

import mobileScreenshotReddit from "./images/mobile-screenshot-reddit.png";
import mobileScreenshotEmojiPicker from "./images/mobile-screenshot-emoji-picker.png";

const Home: Component<{ isFirstRender: boolean }> = (props) => {
  const navigate = useNavigate();

  // If a logged-in user types www.ourapp.com we want them to go straight to
  // the app. But if they're already in our app and try to visit the home
  // page, we want that to work.
  if (
    props.isFirstRender &&
    authnstate.session &&
    !window.location.pathname.startsWith("/app")
  ) {
    queueMicrotask(() => navigate("/app"));
    return <div />;
  }
  return (
    <div class="w-full max-w-xl">
      <section class="bg-blue-100 rounded-xl text-blue-900">
        <h1 class="font-bold text-3xl text-white bg-blue-900 rounded-t-md p-4 underline decoration-white decoration-dotted">
          Always have something to read
        </h1>

        <div class="flex justify-around flex-wrap sm:flex-nowrap py-4 px-4">
          <div>
            <p>
              ReadStuffLater is an app for enthusiastic readers who want to keep
              their minds busy.
            </p>

            <p class="mt-2">
              When you find something interesting, we'll hold onto it until
              you're ready to read it.
            </p>

            <p class="italic text-gray-700 mt-2">
              Reading is a treat, not a chore!
            </p>

            <section class="mt-4 sm:mt-12 ml-auto">
              <Button style="PancakeCta">
                <A href="/signup" data-cy="sign-up" class="mb-2 inline-block">
                  Sign up
                </A>
              </Button>
              <div>
                <p class="italic text-gray-700">Two week free trial, then</p>
                <Pricing />
              </div>
            </section>
          </div>

          <img
            class="w-full sm:w-1/2 mt-4 sm:mt-0 border-4 border-blue-200 rounded-md bg-white"
            src={mobileScreenshotReddit}
            alt="App screenshot"
            style="mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 90%, transparent 100%); -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 90%, transparent 100%)"
          />
        </div>
      </section>

      <section class="mt-8 p-4 bg-emerald-50 text-emerald-900 rounded-lg">
        <h2 class="font-semibold text-2xl underline decoration-emerald-800 decoration-dotted w-max mb-4">
          Why use ReadStuffLater?
        </h2>

        <article>
          <h3 class="font-semibold text-lg">
            Scrolling endlessly on social media feels empty
          </h3>

          <p class="mt-2">Gather worthwhile reads and videos in one place.</p>
          <p class="mt-2">
            The best reading material around, curated by you, for you.
          </p>
        </article>

        <article class="mt-8">
          <h3 class="font-semibold text-lg">Stop hoarding tabs</h3>

          <p class="mt-2">
            Do you have a million web pages open that you're sure you'll get to
            later? Someday? Eventually?
          </p>
          <p class="mt-2">
            Free up memory - in your computer, and in your mind. Save your links
            in ReadStuffLater!
          </p>
        </article>

        <article class="mt-8">
          <h3 class="font-semibold text-lg">There's no pressure</h3>

          <p class="mt-2">
            Read as much or as little as you want - it's up to you.
          </p>
          <p class="mt-2">
            Give yourself permission to get rid of stuff you won't get around
            to. It's okay 🙂
          </p>
          <p class="mt-2">Reading should make you feel good, not guilty!</p>
        </article>
      </section>

      <section class="mt-4 p-4 bg-rose-50 text-red-900 rounded-lg mb-32">
        <h2 class="font-semibold text-2xl underline decoration-red-800 decoration-dotted w-max mb-4">
          How it works
        </h2>
        <div class="flex flex-col">
          <div>
            <p>Add links with just a right-click!</p>

            <p class="mt-2">
              You won't get overwhelmed with organizing things - we
              automatically group links by site.
            </p>

            <p class="mt-2">
              If you want a little organization, group links by labeling them
              with with emoji 🥳.
            </p>
          </div>

          <img
            class="mt-4 border-4 border-rose-200 rounded-md bg-white object-cover object-top w-2/3 h-96 self-center"
            src={mobileScreenshotEmojiPicker}
            alt="App screenshot"
            style="mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 70%, transparent 100%); -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 70%, transparent 100%)"
          />
        </div>

        <div class="mt-8 flex gap-3 items-center">
          <Button style="PancakeCta">
            <A href="/signup">Sign up</A>
          </Button>
          <div>
            <p class="italic text-gray-700">Two week free trial, then</p>
            <Pricing />
          </div>
        </div>
      </section>
    </div>
  );
};
export default Home;
