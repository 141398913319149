import clsx from "clsx";
import { createSignal, onMount, onCleanup } from "solid-js";

import type { Component } from "solid-js";

const Bubble: Component = () => {
  const [bouncing, setBouncing] = createSignal(true);

  onMount(() => {
    const timeout = setTimeout(() => {
      setBouncing(false);
    }, 3_000);
    onCleanup(() => {
      clearTimeout(timeout);
    });
  });

  // inline-block because inline elements can't have a fixed width
  return (
    <span
      class={clsx(
        "w-2 h-2 bg-red-500 inline-block first:mr-1 last:ml-1",
        bouncing() && "animate-bounce"
      )}
      style="border-radius: 50%;"
      data-cy="bubble"
    ></span>
  );
};
export default Bubble;
