import { stripIndent } from "common-tags";

import type { Component } from "solid-js";

import { miscellaneous } from "./helpers.js";
import * as colors from "./lib/colors.js";

import type { Domain } from "../server/types.js";

interface LinkGroupStylesProps {
  domains: Domain[];
}
const LinkGroupStyles: Component<LinkGroupStylesProps> = (props) => {
  const domainRules = () =>
    [miscellaneous, ...props.domains]
      .map((domain) => colors.createCssRule(domain))
      .join("\n");

  return (
    <style>
      {stripIndent`
          :root {
            --summary-transparent-splitpoint: 75%;
          }
          /** Tailwind's small breakpoint */
          @media (min-width: 640px) {
            :root {
              --summary-transparent-splitpoint: 55%;
            }
          }
          /** Tailwind's medium breakpoint */
          @media (min-width: 768px) {
            :root {
              --summary-transparent-splitpoint: 35%;
            }
          }

          ${domainRules()}`}
    </style>
  );
};
export default LinkGroupStyles;
