import {
  createTRPCProxyClient,
  createWSClient,
  splitLink,
  httpLink,
  wsLink,
} from "@trpc/client";
import superjson from "superjson";

import type { TrpcRouter } from "../../server/server.js";

const websocketUrl = new URL(window.location.href);
websocketUrl.hash = "";
websocketUrl.protocol = websocketUrl.protocol === "https:" ? "wss:" : "ws:";
websocketUrl.pathname = "/trpc";
const wsClient = createWSClient({
  url: websocketUrl.toString(),
});
const proxy = createTRPCProxyClient<TrpcRouter>({
  links: [
    // We have to route queries & mutations over HTTP because we can't set
    // cookies over a preexisting websocket connection.
    splitLink({
      condition: (op) => op.type === "subscription",
      true: wsLink({ client: wsClient }),

      // Do not use the batching link unless we enable non-batch requests via
      // request context (e.g., so the email field on the profile page isn't
      // held up by the Stripe subscription lookup)
      false: httpLink({ url: "/trpc" }),
    }),
  ],

  transformer: superjson,
});

const heartbeat = () => {
  proxy.public.websocketHeartbeat.subscribe(undefined, {
    onError: heartbeat,
    onStopped: heartbeat,
  });
};
heartbeat();

export default proxy;
