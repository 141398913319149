import { stripIndent } from "common-tags";
import colorConvert from "color-convert";

import { classNameForDomain } from "./linkGroupStyles.js";

import type { Domain } from "../../server/types.js";

export type RGB = [number, number, number];
type HSL = { h: number; s: number; l: number };
// This type is decided by color-convert, but the community type definitions
// don't export the color types from the package
export type HWB = [number, number, number];

const __crcTable: Record<number, number> = (() => {
  let c: number;
  const crcTable = [];
  for (let n = 0; n < 256; n++) {
    c = n;
    for (let k = 0; k < 8; k++) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1;
    }
    crcTable[n] = c;
  }
  return crcTable;
})();
const crc32 = (str: string) => {
  let crc = 0 ^ -1;

  for (let i = 0; i < str.length; i++) {
    crc = (crc >>> 8) ^ __crcTable[(crc ^ str.charCodeAt(i)) & 0xff];
  }

  return (crc ^ -1) >>> 0;
};

// From https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
/*
const rgb2hex = (r: number, g: number, b: number) => {
  return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};
*/

// Derived from https://css-tricks.com/converting-color-spaces-in-javascript/
const rgb2hsl = ([r, g, b]: [number, number, number]): HSL => {
  // Make r, g, and b fractions of 1
  r /= 255;
  g /= 255;
  b /= 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  // Calculate hue
  // No difference
  if (delta == 0) h = 0;
  // Red is max
  else if (cmax == r) h = ((g - b) / delta) % 6;
  // Green is max
  else if (cmax == g) h = (b - r) / delta + 2;
  // Blue is max
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

// From https://css-tricks.com/converting-color-spaces-in-javascript/
export const hsl2hex = ({ h, s, l }: { h: number; s: number; l: number }) => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  let rStr = Math.round((r + m) * 255).toString(16);
  let gStr = Math.round((g + m) * 255).toString(16);
  let bStr = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (rStr.length == 1) rStr = `0${r}`;
  if (gStr.length == 1) gStr = `0${g}`;
  if (bStr.length == 1) bStr = `0${b}`;

  return "#" + rStr + gStr + bStr;
};

export const hexToRgb = (hex: string): RGB => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)!;
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
};

const darkenHsl = ({ h, s, l }: HSL): HSL => {
  return {
    h,
    s,
    l: Math.min(l * 0.6, 40),
  };
};
const darkenHwb = ([h, w, b]: HWB): HWB => {
  //return [h, w, 0];
  return [h, w, Math.min(80, b * 1.25)];
};

const saturateHsl = ({ h, s, l }: HSL): HSL => {
  return {
    h,
    s: Math.min(s * 1.25, 100),
    l,
  };
};
const saturateHwb = ([h, w, b]: HWB): HWB => {
  return [h, Math.max(20, w * 0.75), Math.max(20, b * 0.75)];
};

const applyColorBoundariesHsl = ({ h, s, l }: HSL): HSL => {
  return {
    h,
    s,
    l: s < 10 ? Math.max(l, 50) : l,
  };
};
/**
 * We're leaving this in place so that if we decide to start applying color
 * boundaries in HWB-space everything that needs the boundaries already asks for
 * them. But for now, none ore neccessary.
 */
const applyColorBoundariesHwb = ([h, w, b]: HWB): HWB => {
  return [h, w, b];
};

export const backgroundColorHsl = (color: RGB): HSL => {
  const fallbackBackgroundColor = [229, 231, 235] as const; // tw-gray-200
  return applyColorBoundariesHsl(
    rgb2hsl((color ?? fallbackBackgroundColor) as RGB)
  );
};
export const foregroundColorHsl = (color: RGB) =>
  hsl2hex(
    saturateHsl(darkenHsl(applyColorBoundariesHsl(backgroundColorHsl(color))))
  )
    // The color format conversion sometimes makes this a decimal value.
    // That's dumb.
    .replace(/\..*/, "");

const getFallbackColor = (domain: Domain): HWB => {
  const [hue, _white, _black] = colorConvert.hex.hwb(
    `#${crc32(domain.domain).toString(16).slice(0, 6)}`
  );
  // Desaturater this because we can get some truly neon colors out of crc32
  return [hue, 35, 35];
};

// HWB-based color conversions
const backgroundColorHwb = (color: RGB): HWB => {
  return applyColorBoundariesHwb(colorConvert.rgb.hwb(color));
};
/**
 * This returns a string because it's what he have to stuff into the 'fill'
 * property in the SVG
 */
export const foregroundColorHwb = (color: RGB): string => {
  const boundedColor = applyColorBoundariesHwb(backgroundColorHwb(color));
  const [, white, black] = boundedColor;
  // If something is white / black, we need to preserve it's high white/black
  // levels, otherwise it gets saturated into its underlying hue which looks
  // nonsensical against the gray background.
  if (white + black >= 90) {
    return hwb2Hex([boundedColor[0], 70, 70]);
  }

  return hwb2Hex([boundedColor[0], 25, 25]);

  // I'm not sure we actually need a clever algorithm since switching from HSL
  // to HWB. A hardcoded adjustment seems to work fine!
  const darkenedColor = darkenHwb(boundedColor);
  const saturatedColor = saturateHwb(darkenedColor);
  return hwb2Hex(saturatedColor);
};

/**
 * color-convert doesn't add the pound-sign, so we have to do it ourselves
 */
const hwb2Hex = (hwb: HWB): string => {
  return `#${colorConvert.hwb.hex(hwb)}`;
};

export const createCssRule = (domain: Domain): string => {
  const color =
    domain.favicon_color ?? colorConvert.hwb.rgb(getFallbackColor(domain));

  const patternUrl = new URL("/pattern.svg", window.location.href);
  patternUrl.searchParams.set("patternNum", domain.pattern.toString());
  patternUrl.searchParams.set("color", JSON.stringify(color));

  // The backend doesn't need these, but they let us debug broken requests
  patternUrl.searchParams.set("user_id", domain.user_id);
  patternUrl.searchParams.set("domain", domain.domain);

  /*
   * We fold the background color into the linear-gradient instead of using
   * `background-color:` because Chrome/Firefox have color bleed when using
   * borde-radius with both background-color and background-image (especially
   * with a dark background-color). By using only the single CSS attribute, the
   * clipping / antialiasing is applied only once, and thus there's no room for
   * inconsistent application.
   *
   * See color bleed demo: https://codepen.io/spiffytech/pen/RwZEvGw
   *
   * We need the final white value because we don't want a transparent pattern
   * that's see-thru to the buttons/text scrolling behind it in sticky mode.
   */
  return stripIndent`
    .${classNameForDomain(domain)} > summary > header {
      background-image: linear-gradient(
        to right,
        var(--tw-gradient-from),
        ${hwb2Hex(backgroundColorHwb(color))} var(--color-splitpoint),
        /* We have to be very explicit about this color, because Chrome/FF fade
        'transparent' to white, while Webkit fades to black */
        rgba(255,255,255,0)
      ),
      url("${patternUrl.toString()}"),
      linear-gradient(#ffffff, #ffffff);
    }
    .${classNameForDomain(domain)} > ul {
      background-color: ${hwb2Hex(backgroundColorHwb(color))}
    }`;
};
