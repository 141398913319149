import "emoji-picker-element";
import { Database as EmojiDatabase } from "emoji-picker-element";
import {
  createMemo,
  createResource,
  createSignal,
  onMount,
  Show,
} from "solid-js";

import type { Component } from "solid-js";

import relativeDataUrl from "../../node_modules/emoji-picker-element-data/en/emojibase/data.json?url";

import BootstrapIcon from "./BootstrapIcon.js";
import Button from "./Button";
import Dialog from "./Dialog";

const dataUrl = new URL(relativeDataUrl, import.meta.url).href;
// Initialize this at load time so we don't try refetching the JSON file every
// time something wants to do a lookup
const emojiDatabase = new EmojiDatabase({ dataSource: dataUrl });

interface Emoji {
  annotation: string;
  group: number;
  order: number;
  shortcodes: string[];
  skins: Array<{ tone: number | number[]; unicode: string; version: number }>;
  tags: string[];
  unicode: string;
  version: number;
}

interface EmojiPickerClickEvent {
  emoji: Emoji;
  skinTone: number | unknown;
  unicode: string;
}

interface EmojiPickerButtonProps {
  onchange: (emoji: string | null) => void;
  value: string | null;
}

interface EmojiPickerProps {
  onchange: (emoji: string) => void;
  buttonEl: HTMLButtonElement | undefined;
}

export const loadGlyph = async (encodedShortcode: string): Promise<string> => {
  const [shortcode, skinTone] = encodedShortcode.split(":");
  const dbEntry: Emoji = (await emojiDatabase.getEmojiByShortcode(
    shortcode
  ))! as unknown as Emoji;

  // Emoji that support skin tones, but are selected as the default yellow, will
  // be assigned a skin tone of 0.
  if (skinTone && skinTone !== "0") {
    return dbEntry.skins.find((skin) => skin.tone === parseInt(skinTone))!
      .unicode;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    return (dbEntry as any)?.unicode;
  }
};

const EmojiPicker: Component<EmojiPickerProps> = ({ onchange }) => {
  let pickerEl: HTMLElement | undefined;
  let pickerParentEl: HTMLDivElement | undefined;

  onMount(() => {
    pickerEl!.addEventListener("emoji-click", (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      const eventDetail = (event as any).detail as EmojiPickerClickEvent;
      const skinTone = eventDetail.skinTone;
      if (typeof skinTone !== "number") {
        throw new Error(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `We expected the emoji's skin tone to be a number, but instead it was ${skinTone}`
        );
      }
      const needsSkinTone = Boolean(
        eventDetail.emoji.skins?.find((skin) => skin.tone)
      );

      const shortcode: string = eventDetail.emoji.shortcodes[0];

      const finalValue = needsSkinTone ? `${shortcode}:${skinTone}` : shortcode;
      onchange(finalValue);
    });
  });

  return (
    <div ref={pickerParentEl} class="flex flex-col">
      <div class="bg-white rounded-md">
        <style>
          {`
                  emoji-picker {
                    --border-size: 0;
                  }
                  `}
        </style>

        {/* TODO: File SolidJS bug about Custom Element types not being
            recognized */}
        {/* @ts-ignore */}
        <emoji-picker ref={pickerEl} data-source={dataUrl} />
      </div>
    </div>
  );
};

const EmojiPickerButton: Component<EmojiPickerButtonProps> = (props) => {
  let buttonEl: HTMLButtonElement | undefined;

  const [open, setOpen] = createSignal(false);

  const [glyph_] = createResource(
    () => props.value,
    async (value) => await loadGlyph(value)
  );
  const glyph = createMemo(() => (props.value === null ? null : glyph_.latest));

  // We want these to be separate functions (rather than a toggle) because
  // closing the dialog results in multiple calls to closePicker() and toggling
  // produces incorrect behavior
  const openPicker = setOpen.bind(null, true);
  const closePicker = setOpen.bind(null, false);

  const controlPicker = (event?: Event) => {
    if (open()) {
      props.onchange(null);
    } else {
      // We add an event listener to window during the click event, and it looks
      // like our handler interrupts propagation, which continues after the
      // handler completes. That means we intercept another click event
      // immediately, interpret it as the 'close' click, and close the picker
      // immediately after opening it. So stop propagation.
      event?.stopPropagation();
      openPicker();
    }
  };

  return (
    <>
      <button
        type="button"
        onclick={controlPicker}
        class="text-gray-500 text-xl px-4 hover:bg-gray-200 "
        ref={buttonEl}
        data-cy="open-emoji-picker"
      >
        <Show when={glyph()} fallback={<BootstrapIcon icon="emoji-smile" />}>
          <Show when={open()} fallback={glyph()}>
            <span>{glyph()} (clear)</span>
          </Show>
        </Show>
      </button>

      {/* This is in a Portal to get it outside the css:contain that the
          linkgroups use. Inside the linkgroups, the linkgroup elements stomp
          all over the picker.*/}
      <Show when={open()}>
        <Dialog
          open
          onclose={closePicker}
          topButton={
            <Button style="PancakeTertiary">
              <button
                onclick={() => {
                  controlPicker();
                  closePicker();
                }}
                data-cy="clear-emoji"
              >
                None
              </button>
            </Button>
          }
        >
          <EmojiPicker
            onchange={(event) => {
              props.onchange(event);
              closePicker();
            }}
            buttonEl={buttonEl}
          />
        </Dialog>
      </Show>
    </>
  );
};

export default EmojiPickerButton;
