import { onCleanup } from "solid-js";
import { createStore } from "solid-js/store";

import type { Component, ParentComponent, JSX } from "solid-js";

const [portals, setPortals] = createStore({} as Record<string, JSX.Element>);

interface PortalProps {
  name: string;
}
export const Portal: Component<PortalProps> = (props) => {
  return portals[props.name] ?? null;
};

interface PortalContentsProps {
  name: string;
}
export const PortalContents: ParentComponent<PortalContentsProps> = (props) => {
  setPortals(props.name, props.children);
  onCleanup(() => {
    setPortals(props.name, undefined);
  });

  return null;
};
