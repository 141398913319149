import { onMount, createSignal, Show } from "solid-js";

import type { Component } from "solid-js";

import trpc from "./lib/trpc.js";

const Pricing: Component = () => {
  const [price, setPrice] = createSignal<Awaited<
    ReturnType<typeof trpc.public.retrieveProductPricing.query>
  > | null>(null);

  onMount(async () => {
    setPrice(await trpc.public.retrieveProductPricing.query());
  });

  return (
    <Show when={price()} fallback={<p>Loading pricing...</p>} keyed>
      {(price) => (
        <p>
          ${price.amount}{" "}
          <span class="text-sm text-gray-700">per {price.interval}</span>
        </p>
      )}
    </Show>
  );
};

export default Pricing;
