import { onCleanup, createEffect, Show } from "solid-js";

import type { ParentComponent, JSX } from "solid-js";

import BootstrapIcon from "./BootstrapIcon";

interface DialogProps {
  open: boolean;
  onclose?: () => void;
  topButton?: JSX.Element;
  class?: string;
}

const Dialog: ParentComponent<DialogProps> = (props) => {
  let dialogEl: HTMLDialogElement | undefined;
  let closeButtonEl: HTMLButtonElement | undefined;

  const mouseEventListener = (event: MouseEvent) => {
    const lookupAncestry = (
      el: Element,
      ancestors: Element[] = []
    ): Element[] => {
      if (!el.parentElement) return ancestors;
      return lookupAncestry(el.parentElement, [...ancestors, el]);
    };
    const ancestors = lookupAncestry(event.target as Element);
    if (ancestors.some((el) => dialogEl!.contains(el))) {
      props.onclose?.();
      window.removeEventListener("click", mouseEventListener);
    }
  };

  const openDialog = () => {
    dialogEl!.showModal();
    // When using .showModal(), the browser will automatically focus the first
    // focusable element inside the dialog. That's the 'close' button, which
    // is dumb and also draws an ugly and confusing focus ring. So as soon as
    // the browser draws the dialog we remove focus from the 'close' button.
    requestAnimationFrame(() => closeButtonEl!.blur());

    // If the user hits the 'Escape' key the browser will close the dialog, and
    // we need to be sure to inform the parent component that that happened
    dialogEl!.addEventListener("close", () => props.onclose?.());

    // If we register this synchronously, the click handler fires DURING the
    // open click and we just immediately close the dialog :(
    requestAnimationFrame(() => {
      window.addEventListener("click", mouseEventListener);
    });
  };

  createEffect(() => {
    // We've learned the hard way that we don't want to toggle. Instead, we want
    // open/close operations to be idempotent, so that if several pieces of
    // logic respond to an interaction we settle on the desired state rather
    // than flapping.
    const shouldBeOpen = props.open;
    // There are two ways to open a <dialog>: as a modal, and as a not-modal.
    // Using the `open` attribute makes it a not-modal, which doesn't work for
    // us. To open it as a modal, we have to use the .show() / .showModal()
    // element methods (which MDN says is the preferred approach anyway).
    if (shouldBeOpen && !dialogEl!.open) {
      openDialog();
    }
    if (!shouldBeOpen && dialogEl!.open) {
      dialogEl!.close();
      props.onclose?.();
    }
  });

  onCleanup(() => {
    if (dialogEl!.open) {
      dialogEl!.close();
      props.onclose?.();
    }
  });

  return (
    // The <dialog> element cannot have display:flex. For some bonkers reason,
    // that makes the dialog always visible, even when closed. But at a
    // different position than if it's open? So maybe it adds it to the DOM flow
    // or something?
    <dialog
      ref={dialogEl}
      classList={{ [props.class ?? ""]: true, "rounded-lg": true }}
    >
      <div class="flex justify-between">
        <Show when={props.topButton}>
          <div class="mr-4">{props.topButton}</div>
        </Show>
        <Show when={props.onclose}>
          <button
            type="button"
            onclick={props.onclose!}
            class="ml-auto px-2 py-1"
            ref={closeButtonEl}
          >
            <BootstrapIcon icon="x-lg" alt="Close the dialog" />
          </button>
        </Show>
      </div>
      {props.children}
    </dialog>
  );
};

export default Dialog;
