import dayjs from "dayjs";

import type { Dayjs } from "dayjs";

import type { Link, Domain } from "../server/types.js";

export const miscellaneous: Domain = {
  user_id: "",
  domain: "Miscellaneous",
  favicon_color: null,
  original_favicon_url: null,
  pattern: 23,
  updated_at: new Date().toISOString(),
  // We should fudge this inside the sorting helper. Choose the unix epoch so
  // it'll float to the bottom and we're guaranteed to notice if we don't
  // correctly apply this.
  last_append: new Date(0),
  high_water_mark: Number.MAX_SAFE_INTEGER,
};

export const presentationalUrl = (url: string) =>
  url.replace(/^(https?:\/\/)?(www\.)?(.*?)\/?$/, "$3");

export const groupLinksByDate = (
  links: Link[],
  domains: Record<string, Domain>,
  oldStuffOnly: boolean
): { label: string; groups: { domain: Domain; links: Link[] }[] }[] => {
  // TODO: This algorithm is slow, because its m*log(n), where n is num links.
  // Better would be inverting it to log(intervals), best would be some kind of
  // 2-pass bucketing algorithm.
  return (
    [
      ["Today", dayjs().startOf("day")],
      ["Yesterday", dayjs().subtract(1, "days").startOf("day")],
      ["A few days ago", dayjs().subtract(3, "days")],
      ["A week ago", dayjs().subtract(1, "weeks")],
      ["Two weeks ago", dayjs().subtract(2, "weeks")],
      ["Three weeks ago", dayjs().subtract(3, "weeks")],
      ["A month ago", dayjs().subtract(1, "month")],
      ["Two months ago", dayjs().subtract(2, "months")],
      ["Several months ago", dayjs().subtract(4, "months")],
      ["Six months ago", dayjs().subtract(6, "months")],
      ["Over a year ago", dayjs().subtract(1, "year")],
    ] as [string, Dayjs][]
  )
    .map(([label, cutoffDate], index, arr) => {
      const linksInBucket = links.filter(
        (link) =>
          dayjs(oldStuffOnly ? link.deleted_at : link.updated_at).isAfter(
            cutoffDate
          ) &&
          (index === 0 ||
            dayjs(oldStuffOnly ? link.deleted_at : link.updated_at).isBefore(
              arr[index - 1][1]
            ))
      );

      return {
        label,
        groups: _groupLinksByDomain(linksInBucket, domains, oldStuffOnly),
      };
    })
    .map(({ label, groups }) => ({
      label,
      groups: groups.filter(({ links }) => links.length > 0),
    }))
    .filter((interval) => interval.groups.length > 0);
};

export const _groupLinksByDomain = (
  links: Link[],
  domains: Record<string, Domain>,
  oldStuffOnly: boolean
): { domain: Domain; links: Link[] }[] => {
  const groupSortComparator = oldStuffOnly
    ? (a: Link, b: Link) => (a.deleted_at! > b.deleted_at! ? -1 : 1)
    : (a: Link, b: Link) => {
        return domains[a.domain].last_append > domains[b.domain].last_append
          ? -1
          : 1;
      };
  const subgroupSortComparator = oldStuffOnly
    ? (a: Link, b: Link) => (a.deleted_at! > b.deleted_at! ? -1 : 1)
    : (a: Link, b: Link) => {
        return a.updated_at > b.updated_at ? -1 : 1;
      };

  links = links.filter((link) =>
    oldStuffOnly
      ? link.deleted_at
      : // Continue showing the most recently deleted link in its usual place,
        // so it's easy to recover.
        !link.deleted_at || link.is_latest_deleted
  );

  const oneOffLinks: { links: Link[]; domain: Domain } = {
    // This should never be less than one, but we previously had a bug where it
    // was initialized at zero and things wouldn't display, so better safe than
    // sorry.
    links: links.filter((link) => domains[link.domain].high_water_mark <= 1),
    // TODO: Approximate a stable last_append value for Miscellaneous by
    // finding the highest last_append on any domain that's not attached to a
    // grouped link. Since we have all domains a user has ever stored, we should
    // be able to do that.
    domain: miscellaneous,
  };

  const groupedLinks = Object.values(
    links
      .filter((link) => domains[link.domain].high_water_mark > 1)
      .reduce((groups, link) => {
        const group = groups[link.domain] ?? [];
        return { ...groups, [link.domain]: [...group, link] };
      }, {} as Record<string, Link[]>)
  ).map((group) => ({ links: group, domain: domains[group[0].domain] }));

  const finalizedGroups = [oneOffLinks, ...groupedLinks]
    // The one-off links group may have zero size
    .filter(({ links }) => links.length > 0)
    .map((group) => ({
      ...group,
      links: group.links.sort(subgroupSortComparator),
    }))
    .sort((a, b) => groupSortComparator(a.links[0], b.links[0]));

  return [...finalizedGroups];
};

/**
 * This ensures that the element is at the same position within the viewport
 * after a layout shift.
 *
 * MUST be called BEFORE triggering the layout shift.
 *
 * IT can only do so much - if the layout shift cuts off enough content, the
 * element will still wind up positioned higher in the viewport than before.
 */
export const retainScrollPosition = (el: Element) => {
  const targetViewportPosition = el.getBoundingClientRect().top;

  requestAnimationFrame(() => {
    const newPagePositon = el.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({ top: newPagePositon - targetViewportPosition });
  });
};
