import dayjs from "dayjs";
import jsCookie from "js-cookie";
import { setUser as sentrySetUser } from "@sentry/browser";
import { createStore } from "solid-js/store";

import { clientVisibleCookieBase } from "../../client-server-shared.js";

import type { ClientVisibleSession } from "../../server/types.js";

export interface AuthnState {
  session: ClientVisibleSession | null;
}

// Will return null if this is just some anonymous user who's not authenticated
export const pullSession = (): ClientVisibleSession | null => {
  const baseCookieName = clientVisibleCookieBase;
  // Look up two different cookie names here, because we can't use __Host-
  // cookies in dev
  const cookieValue =
    jsCookie.get(baseCookieName) ?? jsCookie.get(`__Secure-${baseCookieName}`);
  if (!cookieValue) return null;
  let session = JSON.parse(cookieValue) as ClientVisibleSession;
  session = {
    ...session,
    access_expires_at: session.access_expires_at
      ? new Date(session.access_expires_at as unknown as string)
      : null,
  };
  sentrySetUser({ id: session.user_id });
  return session;
};

const [authnState, setAuthnState] = createStore<AuthnState>({
  session: pullSession(),
});
export default authnState;
export { setAuthnState };

export const verifyAuthentication = () => {
  // Ensure this is current so we don't have to remember this at all the auth
  // mutation call sites
  setAuthnState("session", pullSession());
  if (!authnState.session) {
    throw new Error("Authentication did not succeed");
  }
};

export const verifyUnauthenticated = () => {
  // Ensure this is current so we don't have to remember this at all the auth
  // mutation call sites
  setAuthnState("session", pullSession());
  if (authnState.session) {
    throw new Error("User was authenticated while they should be logged out");
  }
};

export const isAccessExpired = () => {
  if (!authnState.session?.access_expires_at) return false;
  return dayjs(authnState.session.access_expires_at).isBefore(dayjs());
};

export const isAccessTimeLimited = () => {
  return authnState.session?.access_expires_at !== null;
};
