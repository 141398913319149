import Debug from "debug";

import idgen from "./server/lib/idgen.js";

import type { Link } from "./server/types.js";

export const clientVisibleCookieBase = "readstufflater-client-visible-session";

export const userSettingsUrl = "/app/settings";
export const subscribeUrl = "/app/subscribe";
export const afterPaymentUrl = `${subscribeUrl}/complete`;

const debugFactory = (
  subsystem: string,
  fn: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ...extra: (string | Function)[]
) => {
  const extraSegments = extra
    .map((segment) => (typeof segment === "function" ? segment.name : segment))
    .join(":");
  return Debug(
    `readstufflater:${subsystem}:${fn}${
      extra.length ? `:${extraSegments}` : ""
    }`
  );
};

/**
 * Generates a new debug instance based on the current module and function
 * @param fileUrl Should always be import.meta.url
 * @param fn A handle to the function this is being invoked inside of
 */
export const debugFactoryServer = (
  fileUrl: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  fn: Function | string,
  // Allow these to be functions in case we're logging from within functions in
  // some closure scope
  // eslint-disable-next-line @typescript-eslint/ban-types
  ...extra: (string | Function)[]
) => {
  const subsystemFromUrl = (url: string) => {
    const modulePath = new URL(url).pathname;
    const pathSegments = modulePath.split("/");
    const lastTwoSegments = pathSegments
      .slice(pathSegments.length - 2)
      // Remove the file extension. Browsers don't include a 'basename' function.
      .map((segment) => segment.replace(/\..*$/, ""));
    return lastTwoSegments.join(":");
  };
  const subsystem = subsystemFromUrl(fileUrl);

  return debugFactory(
    subsystem,
    typeof fn === "function" ? fn.name : fn,
    ...extra
  );
};

/**
 * Vite won't correctly set up import.meta.url at runtime. And with minification
 * turned on, fn.name just returns gibberish. So we have to pass hardcoded
 * strings here in the browser.
 */
export const debugFactoryBrowser = (
  subsystem: string,
  fn: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ...extra: (string | Function)[]
) => {
  return debugFactory(subsystem, fn, ...extra);
};

export const initEmptyLink = (url: string, userId: string): Link => {
  const parsedUrl = new URL(url);
  if (!parsedUrl.protocol.startsWith("http") || !parsedUrl.hostname) {
    throw {
      statusCode: 400,
      message: "The URL you provided is not in a valid format.",
    };
  }
  return {
    link_id: idgen(),
    user_id: userId,
    title: null,
    description: null,
    url: url.toString(),
    original_url: url.toString(),
    domain: parsedUrl.hostname,
    deleted_at: null,
    is_latest_deleted: false,
    emoji: null,
    created_at: new Date(),
    updated_at: new Date(),
  };
};
