import clsx from "clsx";
import { createSignal, createMemo, Show } from "solid-js";

import type { Component } from "solid-js";

import BootstrapIcon from "./BootstrapIcon.js";

import type { Domain } from "../server/types.js";

const Favicon: Component<{ domain: Domain }> = (props) => {
  // Determining the favicon URL by having the server perform a redirect is
  // pretty slow on bad network connections, so code the client to point
  // straight there.
  const faviconBaseUrl = import.meta.env.VITE_faviconBaseUrl as
    | string
    | undefined;
  if (!faviconBaseUrl) {
    throw new Error("Must supply VITE_faviconBaseUrl");
  }

  // We need the base URL to end in a slash if it's going to join properly
  const faviconUrl = createMemo(
    () =>
      props.domain.original_favicon_url &&
      new URL(
        props.domain.domain,
        `${faviconBaseUrl}/`.replace(/\/\/$/, "/")
      ).toString()
  );
  const [tries, setTries] = createSignal(0);
  const [activeImgUrl, setActiveImgUrl] = createSignal<string | null>(
    faviconUrl()
  );
  const [success, setSuccess] = createSignal(false);
  // We have to track giving up, otherwise we'll retry this loop on every render
  // of this or a parent component
  const [gaveUp, setGaveUp] = createSignal(false);

  const showFallback = () => !success() || !activeImgUrl();
  // We don't want the fallback icon to blink each time we try loading the
  // image, so instead hide the image until we confirm it loads correctly,
  // _then_ show it.
  //
  // Somehow after this runs args.update(), the onload callback gets run
  // again, and then we're just in an infinite loop as fast as Forgo can
  // render. And then, to boot, the handler persists across rerenders
  // because the browser holds a reference to the function I guess? So only
  // set the onload handler if we're not yet loaded.
  const onload = () => {
    setSuccess(true);
  };

  const onerror = async () => {
    // TODO: apply an exponential backoff here so that we can eventually
    // load the favicon after it finishes. ArcProjector's queue has
    // ready-to-go backoff code. Alternatively, have the server instruct the
    // client to await the favicon job instead of guessing when the image is
    // ready.
    setTries((tries) => (tries += 1));
    if (tries() >= 3) {
      setGaveUp(true);
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));
    setActiveImgUrl(faviconUrl());
  };

  return (
    <>
      <Show when={showFallback()}>
        <BootstrapIcon icon="globe" alt="This site has no icon" />
      </Show>

      <Show when={activeImgUrl() && !gaveUp()}>
        <img
          src={activeImgUrl() ?? undefined}
          onload={success() ? undefined : onload}
          onerror={onerror}
          class={clsx("h-6 w-6 rounded-md", { hidden: showFallback() })}
          alt={`Favicon for ${props.domain.domain}`}
          aria-hidden
        />
      </Show>
    </>
  );
};
export default Favicon;
