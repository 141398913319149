import { setUser as sentrySetUser } from "@sentry/browser";
import clsx from "clsx";
import { createSignal, Show } from "solid-js";
import { A, useMatch } from "@solidjs/router";

import type { Component, ParentComponent } from "solid-js";

import authnstate, {
  pullSession,
  verifyUnauthenticated,
} from "./lib/authnstate.js";
import trpc from "./lib/trpc.js";
import { userSettingsUrl } from "../client-server-shared.js";
import { getAccessExpiryHours } from "./lib/accessExpiry.jsx";

import AccessExpiryButton from "./AccessExpiryButton.js";
import BootstrapIcon from "./BootstrapIcon.js";
import Help from "./Help.jsx";

export const [open, setOpen] = createSignal(false);

const SidebarMatchStyling: ParentComponent<{ href: string }> = (props) => {
  const match = useMatch(() => props.href);

  return (
    <>
      {/* Tailwind sizes padding in rems, but borders in px, so we can't use
              variable padding to align all our text. Instead, use a fixed-width
              element. */}
      <span
        class={clsx(
          "self-stretch ml-auto sm:ml-0 sm:mr-3 order-last sm:order-first",
          // This shadow is insanely subtle, but it *is* noticeable.
          (match() && "border-l-2 border-orange-700") || "w-[2px]"
        )}
      />

      <div
        classList={{
          "bg-orange-50 rounded-md font-semibold shadow-orange-50 shadow-md":
            Boolean(match()),
        }}
      >
        {props.children}
      </div>
    </>
  );
};

const SidebarItemHeader: ParentComponent = (props) => {
  return <span class="underline">{props.children}</span>;
};
const SidebarItemIcon: Component<{ icon: string }> = (props) => {
  return (
    <span class="mr-2">
      <BootstrapIcon icon={props.icon} />
    </span>
  );
};

const SidebarItemInner: ParentComponent<{ icon: string }> = (props) => {
  return (
    <div class="flex px-2 py-1">
      <SidebarItemIcon icon={props.icon} />
      {props.children}
    </div>
  );
};

const SidebarItem: ParentComponent<{
  href?: string;
  "data-cy"?: string;
}> = (props) => {
  return (
    <li class="flex items-baseline">
      <SidebarMatchStyling href={props.href ?? "nonce"}>
        {props.children}
      </SidebarMatchStyling>
    </li>
  );
};

export const logOut = async (event: Event) => {
  event.preventDefault();
  // We have to clear out any Sentry stuff we set so it doesn't linger
  // in memory. Sentry doesn't have any kind of global clear logic.
  sentrySetUser(null);

  await trpc.user.logout.mutate();

  authnstate.session = pullSession();
  verifyUnauthenticated();
  // Force a hard page navigation to guarantee we've cleared anything out of
  // memory, any open connections (WebSockets), etc.
  document.location.href = "/";
};

const Sidebar: Component = () => {
  let sidebarEl: HTMLUListElement | undefined;

  const accessExpiryHours = getAccessExpiryHours();

  // `isolate` and z-index are critical here. `isolate` creates a new
  // stacking context, which lets the sidebar paint over `contain:`
  // elements. Then we have to use the z-index to get it to actually do so.
  // `contain` creates a new stacking context, but since those elements are
  // rendered later, their stacking context defaults to being on top of the
  // sidebar's context.
  return (
    <ul
      class={clsx(
        `h-full flex-initial sm:mr-4 border-l-2 border-gray-500
              sm:border-0 pl-4 sm:pl-0 flex-col gap-2 w-max sm:flex order-last ml-4
              sm:ml-0 sm:order-first bg-white absolute sm:static right-0 isolate z-[10000]`,
        open() ? "flex" : "hidden"
      )}
      onclick={(event) => {
        if (event.target !== sidebarEl) {
          setOpen(false);
        }
      }}
      ref={sidebarEl}
      data-cy="sidebar"
    >
      <SidebarItem href="/app">
        <SidebarItemInner icon="book">
          <A href="/app" data-cy="reading-list">
            <SidebarItemHeader>Reading List</SidebarItemHeader>
          </A>
        </SidebarItemInner>
      </SidebarItem>

      <SidebarItem href="/app/history">
        <SidebarItemInner icon="clock-history">
          <A href="/app/history" data-cy="history">
            <SidebarItemHeader>History</SidebarItemHeader>
          </A>
        </SidebarItemInner>
      </SidebarItem>

      <Show when={accessExpiryHours()}>
        <li class="px-1">
          <hr class="border-gray-400 border-1 my-2" />
        </li>
        <SidebarItem>
          <SidebarItemInner icon="alarm">
            <AccessExpiryButton />
          </SidebarItemInner>
        </SidebarItem>
      </Show>

      <li class="px-1">
        <hr class="border-gray-400 border-1 my-2" />
      </li>

      <li>
        <SidebarItem href={userSettingsUrl}>
          <SidebarItemInner icon="gear">
            <A href={userSettingsUrl} data-cy="profile-settings">
              <SidebarItemHeader>Settings</SidebarItemHeader>
            </A>
          </SidebarItemInner>
        </SidebarItem>
      </li>

      <Show when={import.meta.env.VITE_showHelpButton === "true"}>
        <SidebarItem>
          <SidebarItemInner icon="question-circle">
            <Help />
          </SidebarItemInner>
        </SidebarItem>
      </Show>

      <li class="px-1">
        <hr class="border-gray-400 border-1 my-2" />
      </li>

      <li class="flex items-center">
        <span class="w-[2px] order-last sm:order-first ml-3 sm:ml-0 sm:mr-3 " />
        <form onsubmit={logOut}>
          <button type="submit" class="px-2 py-1" data-cy="logout">
            <span class="mr-2">
              <BootstrapIcon icon="box-arrow-left" />
            </span>
            Log out
          </button>
        </form>
      </li>
    </ul>
  );
};
export default Sidebar;
