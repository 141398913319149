import { A, useMatch, useNavigate, useSearchParams } from "@solidjs/router";
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
  lazy,
} from "solid-js";
import Debug from "debug";
const debug = Debug("readstufflater:links");
debug("hello");

import type { Component } from "solid-js";

import ActiveEmojiPicker from "./ActiveEmojiPicker.js";
const AddLink = lazy(() => import("./AddLink.jsx"));
import BootstrapIcon from "./BootstrapIcon.js";
import Button from "./Button.js";
import LinkGroup from "./LinkGroup.jsx";
import LinkGroupStyles from "./LinkGroupStyles.js";
import { Portal } from "./Portal.js";
import Sidebar from "./Sidebar.js";

import { subscribeUrl } from "../client-server-shared.js";
import { isAccessExpired, verifyAuthentication } from "./lib/authnstate.js";
import useLinksStateContext from "./lib/linksstate.jsx";
import trpc from "./lib/trpc.js";

import type { Link, LinksList } from "../server/types.js";

const historyUrl = "/app/history";

/**
 * This is only for handling page load
 */
const loadInitialLinks = (bulkUpsert: (data: LinksList) => void) => {
  const preloadedLinks = document.getElementById("preloaded-links");
  if (preloadedLinks) {
    const links = JSON.parse(
      decodeURIComponent(preloadedLinks.textContent!)
    ) as unknown as LinksList;
    // Ensure that if we navigate away from the Links component and come back,
    // we fetch fresh links, rather than showing whatever the original
    // server-rendered HTML contained.
    preloadedLinks.remove();
    bulkUpsert(links);
  }
};

// TODO: Inline this into its one call site?
const fetchLinksAndDomains = async (bulkUpsert: (data: LinksList) => void) => {
  const data = await trpc.app.links.retrieve.query({ load: "full" });
  bulkUpsert(data);
};

const Links: Component = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  try {
    verifyAuthentication();
  } catch {
    onMount(() => {
      // We can't call navigateTo on page load, because it requires the router
      // to be fully rendered first, and we'd be calling it inside the first
      // render cycle.
      navigate("/login");
    });
    // We need to actually return an element for the mount() event handler
    // to fire.
    return <p>Redirecting to the login page...</p>;
  }

  const { linksState } = useLinksStateContext();

  // Make sure this happens before mount. We can synchronously load the initial
  // batch of links (if available), which prevents a flash of the "you have no
  // links" notice during page load.
  void loadInitialLinks(linksState.bulkUpsert.bind(linksState));

  const [addLinkOpen, setAddLinkOpen] = createSignal(false);
  const longPollLinks = async (lastObservedLink: Date | null) => {
    const links = await trpc.app.links.longPollLinkUpdates.query(
      lastObservedLink?.toISOString() ?? null
    );
    if (!links) return null;

    links.forEach(({ linkId, link, domain }) => {
      if (!link) {
        linksState.deleteLink(linkId);
        return;
      }

      linksState.upsertDomain(domain);
      linksState.upsertLink(link);
    });

    const linkDates = links
      .map(({ link }) => link?.updated_at?.getTime())
      .filter((d): d is number => Boolean(d));
    if (linkDates.length === 0) return null;
    return new Date(Math.max(...linkDates));
  };

  onMount(async () => {
    await fetchLinksAndDomains(linksState.bulkUpsert.bind(linksState));

    // I'm hitting an issue where TRPC doesn't always resubscribe after the
    // websocket gets disconnected. Not sure why. Since ReadStuffLater is in
    // maintenance mode, just toggle long polling on and move on with my life.
    //
    // Tried it, it doesn't work. On mobile, it just doesn't update the link
    // immediately when clicked (it does when the mark-read button is used). It
    // also misses updates from other devices. Dunno why yet.
    const useLongPolling = false;

    if (useLongPolling) {
      // Every time we get a new link, we request links updated after it, ensuring
      // we get all updates
      let lastObservedLink: Date | null = null;
      while (true) {
        try {
          lastObservedLink = await longPollLinks(lastObservedLink);
        } catch (ex) {
          console.error(ex);
          // Don't get stuck in a crazy reconnect loop
          await new Promise((resolve) => setTimeout(resolve, 15_000));
        }
      }
    } else {
      const subscribe = () => {
        const subscription = trpc.app.links.subscribe.subscribe(undefined, {
          onData: (data) => {
            console.log("onData");
            const { linkId, link, domain } = data;

            if (!link) {
              linksState.deleteLink(linkId);
              return;
            }

            linksState.upsertDomain(domain);
            linksState.upsertLink(link);
          },
          onStarted: async () => {
            // Refetch links every time the websocket reconnects, to ensure we
            // have the latest correct data any time the sure looks at the app. If
            // we don't do this, a user whose device was asleep will have a weird
            // gap where they have stale data, plus the latest data for individual
            // records that changed after resuming from suspend. That's bad, just
            // make sure they have the latest *everything*.
            //
            // We also rely on this to fetch their deleted records when the app
            // first boots.
            await fetchLinksAndDomains(linksState.bulkUpsert.bind(linksState));
          },
          onStopped: () => {
            console.log("Stopped");
            subscribe();
          },
          onError: (...args) => {
            console.log("Error", args);
            subscribe();
          },
        });
        onCleanup(() => subscription.unsubscribe());
      };
      subscribe();
    }
  });

  createEffect(() => {
    if (isAccessExpired()) {
      navigate(subscribeUrl);
    }
  });

  const shortcode = (): string | null => searchParams.shortcode ?? null;
  const oldStuffOnly = useMatch(() => "/app/history");

  const visibleLinks = createMemo(() => {
    const linksComparator = oldStuffOnly()
      ? (a: Link, b: Link) => (a.deleted_at! > b.deleted_at! ? -1 : 1)
      : (a: Link, b: Link) => (a.created_at > b.created_at ? -1 : 1);

    const deletedFilter: (link: Link) => boolean = oldStuffOnly()
      ? (link) => Boolean(link.deleted_at)
      : (link) => link.deleted_at === null || link.is_latest_deleted;
    const visibleLinks = Object.values(linksState._links).filter((link) => {
      // Don't filter by emoji on the history page
      if (oldStuffOnly()) {
        return deletedFilter(link);
      }
      return link.emoji === shortcode() && deletedFilter(link);
    });

    const linksByDomain = new Map<string, Link[]>();
    visibleLinks.forEach((link) => {
      if (!linksByDomain.get(link.domain)) {
        linksByDomain.set(link.domain, []);
      }
      linksByDomain.get(link.domain)!.push(link);
    });

    linksByDomain.forEach((links) => {
      links.sort(linksComparator);
    });

    const miscLinks: Link[] = [];
    linksByDomain.forEach((links, domain) => {
      if (linksState.domains[domain].high_water_mark <= 1) {
        miscLinks.push(...links);
        linksByDomain.delete(domain);
      }
    });
    if (miscLinks.length > 0)
      linksByDomain.set("Miscellaneous", miscLinks.sort(linksComparator));
    return linksByDomain;
  });

  const linkGroups = createMemo(() => {
    const groupComparator = oldStuffOnly()
      ? (a: Link, b: Link) => (a.deleted_at! > b.deleted_at! ? -1 : 1)
      : (a: Link, b: Link) =>
          linksState.domains[a.domain].last_append >
          linksState.domains[b.domain].last_append
            ? -1
            : 1;

    const sortedDomains = Array.from(visibleLinks().entries()).sort(
      ([, a], [, b]) => groupComparator(a[0], b[0])
    );
    return sortedDomains.map(([domain]) => linksState.domains[domain]);
  });

  // If the user deselects the last link bearing an emoji, they'll see an
  // empty list, but due to the way we coded the picker it'll show 'None'
  // even though a shortcode is selected
  createEffect(() => {
    if (shortcode() && visibleLinks().size === 0) {
      console.log("No relevant links found");
      setSearchParams({ shortcode: null });
    }
  });

  return (
    <div class="flex relative w-full">
      <Sidebar />

      {/* min-w-0 is necessary to get ellipsis abbreviation of link group
              headers to work for exceptionally-long domains */}
      <div class="w-full flex-1 min-w-0" aria-label="inbox" data-cy="inbox">
        <Show
          when={!oldStuffOnly()}
          fallback={
            <div class="mb-4">
              <header class="text-xl font-semibold">History</header>
              <p class="italic">Keeps the last 7 days of read items</p>
            </div>
          }
        >
          <div class="mb-4">
            <Show
              when={addLinkOpen()}
              fallback={
                <div class="flex items-baseline">
                  <ActiveEmojiPicker
                    selected={shortcode()}
                    onchange={(val) => {
                      setSearchParams({ shortcode: val });
                    }}
                  />

                  <Button style="PancakeTertiary">
                    <button
                      type="button"
                      class="flex whitespace-nowrap ml-auto"
                      onclick={() => {
                        setAddLinkOpen(true);
                      }}
                      data-cy="add-link"
                    >
                      <span class="mr-2">
                        <BootstrapIcon icon="cloud-plus" />
                      </span>
                      Add Link
                    </button>
                  </Button>
                </div>
              }
            >
              <div class="flex">
                <AddLink
                  close={() => {
                    setAddLinkOpen(false);
                  }}
                />
                <Button style="PancakeSecondary">
                  <button
                    class="ml-2 md:ml-4 whitespace-nowrap overflow-x-visible"
                    onclick={() => {
                      setAddLinkOpen(false);
                    }}
                  >
                    <BootstrapIcon icon="x-lg" />
                  </button>
                </Button>
              </div>
            </Show>
          </div>
        </Show>

        <LinkGroupStyles domains={Object.values(linksState.domains)} />

        <Portal name="emoji-picker" />

        <Show
          when={visibleLinks().size === 0}
          fallback={
            <For each={linkGroups()}>
              {(domain) => (
                <LinkGroup
                  domain={domain}
                  links={visibleLinks().get(domain.domain)!}
                />
              )}
            </For>
          }
        >
          <div
            class="mx-auto italic text-gray-500 text-center"
            data-cy="empty-list-message"
          >
            <p class="text-lg">You don't have any links to show.</p>
            <p class="text-sm">
              <Show
                when={!oldStuffOnly()}
                fallback={
                  <p>
                    Links will appear here after you read them.{" "}
                    <A href="/app" class="underline">
                      Try reading something now!
                    </A>
                  </p>
                }
              >
                Save a link for later, or read something from your{" "}
                <A href={historyUrl} class="underline">
                  History
                </A>{" "}
                list.
              </Show>
            </p>
          </div>
        </Show>
      </div>
    </div>
  );
};
export default Links;
