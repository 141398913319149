import { createResource, For } from "solid-js";

import type { Component } from "solid-js";

import { loadGlyph } from "./EmojiPicker.js";

import useLinksStateContext from "./lib/linksstate.jsx";

interface ListOfUsedEmojiProps {
  selected: string | null;
  onchange: (val: string | null) => void;
}

const ActiveEmojiPicker: Component<ListOfUsedEmojiProps> = (props) => {
  const { emojiShortcodes } = useLinksStateContext();

  // The resource doesn't react to store field changes. Is this intentional, or
  // a SolidJS bug?
  const [emojis] = createResource(
    emojiShortcodes,
    async (
      shortcodes
    ): Promise<
      ({ count: number } & (
        | { shortcode: string; emoji: string }
        | { shortcode: null; emoji: null }
      ))[]
    > => {
      return await Promise.all(
        shortcodes.map(async ({ shortcode, count }) => {
          if (!shortcode) {
            return { shortcode: null, emoji: null, count };
          }

          return {
            shortcode,
            emoji: await loadGlyph(shortcode),
            count,
          };
        })
      );
    }
  );

  const isSelected = (shortcode: string | null) => props.selected === shortcode;

  const excludedEmojiCount = () => {
    if (!emojis.latest) return 0;
    return emojis().reduce(
      (acc, { shortcode, count }) =>
        isSelected(shortcode) ? acc : acc + count,
      0
    );
  };

  const onchange_ = (event: Event) => {
    const value: string | null = (event.target as HTMLSelectElement).value;
    const shortcode = value === "" ? null : value;
    props.onchange(shortcode);
  };

  return (
    <div class="flex items-baseline">
      <span class="italic mr-2 text-gray-700 whitespace-nowrap">Show tag:</span>
      <div class="flex flex-wrap items-center">
        <select
          class="px-2 py-1 bg-white border border-gray-300 rounded-md w-max"
          data-cy="active-emoji-picker"
          onchange={onchange_}
        >
          <For each={emojis()}>
            {({ emoji, shortcode, count }) => (
              <option
                class="px-2 py-1 text-xl border-2 rounded-xl w-max"
                classList={{
                  "bg-blue-200 border-blue-300 text-blue-900":
                    props.selected === shortcode,
                }}
                data-emoji={shortcode ?? ""}
                value={shortcode ?? ""}
                selected={isSelected(shortcode)}
              >
                {emoji ?? "N/A"} : {count}
              </option>
            )}
          </For>
        </select>
        <span class="ml-2 text-sm text-gray-700 italic">
          ({excludedEmojiCount()} hidden)
        </span>
      </div>
    </div>
  );
};

export default ActiveEmojiPicker;
