import { A, Route, Router, Routes, useMatch } from "@solidjs/router";
import { createSignal, lazy, Match, onMount, Show, Switch } from "solid-js";

import type { Component } from "solid-js";

import authnstate from "./lib/authnstate.js";
import { getAccessExpiryHours } from "./lib/accessExpiry.jsx";
import { LinksStateProvider } from "./lib/linksstate.jsx";
import { logOut, setOpen as setSidebarOpen } from "./Sidebar.js";

import { subscribeUrl, userSettingsUrl } from "../client-server-shared.js";
import Button from "./Button.js";
import Bubble from "./Bubble.js";
import Home from "./Home.js";
import Links from "./Links.jsx";

const LinkShareResult = lazy(() => import("./LinkShareResult.js"));
const Login = lazy(() => import("./Login.jsx"));
const Signup = lazy(() => import("./Signup.jsx"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy.js"));
const Subscribe = lazy(() => import("./Subscribe.js"));
const UserSettings = lazy(() => import("./UserSettings.js"));

const AnonymousMenu: Component = () => {
  return (
    <>
      <li class="flex items-center">
        <Button style="PancakeCta">
          <A href="/signup" data-cy="sign-up">
            Sign up
          </A>
        </Button>
      </li>

      <li class="flex items-center ml-4">
        <Button style="PancakeSecondary">
          <A href="/login" data-cy="log-in">
            Log In
          </A>
        </Button>
      </li>
    </>
  );
};

const UserMenu: Component = () => {
  const logoutButton = (
    <li class="flex items-center">
      <form onsubmit={logOut}>
        <Button style="PancakeSecondary" type="submit">
          <button
            class="px-2 py-1 border border-gray-500 rounded-md"
            data-cy="logout"
          >
            Log out
          </button>
        </Button>
      </form>
    </li>
  );

  const isInsideApp = useMatch(() => "/app/*");
  const isInsideMainApp = useMatch(() => "/app");
  const matchesSettingsUrl = useMatch(() => userSettingsUrl);
  const matchesSubscribeUrl = useMatch(() => subscribeUrl);
  const matchesHistoryUrl = useMatch(() => "/app/history");

  // TODO: We should really have a way for a page to specify + request its
  // own sidebar, rather than trying to have a universal sidebar for the ONE
  // page that needs one and then patch it out everywhere. Maybe this is a
  // good use for an enhanced Portal component?
  const showHamburger = () => isInsideMainApp() || matchesHistoryUrl();
  const accessExpiryHours = getAccessExpiryHours();
  return (
    <>
      <Switch>
        {/* We don't want to show "Go to the app" when the user is already in the app */}
        <Match when={!isInsideApp()}>
          <li class="flex items-center">
            <A href="/app" class="border border-gray-500 rounded-md px-2 py-1">
              Go to the app
            </A>
          </li>
        </Match>

        <Match when={matchesSettingsUrl() || matchesSubscribeUrl()}>
          {
            // TODO: This is an ugly hack to not have to make the sidebar
            // context-aware. We should really make DRY the logout button logic,
            // and not special-case this one place to show the button out of the
            // whole app
            logoutButton
          }
        </Match>
      </Switch>

      <Show when={showHamburger()}>
        <Button style="PancakeTertiary">
          {/* People apparently can't reliably understand the hamburger icon,
              but *can* understand the word 'Menu'.
              https://news.ycombinator.com/item?id=34546748
          */}
          <button
            type="button"
            onclick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
            class="text-gray-500 visible sm:hidden flex items-center"
            aria-label="Toggle sidebar"
            data-cy="menu-button"
          >
            <Show when={accessExpiryHours() !== null}>
              <Bubble />
            </Show>
            Menu
          </button>
        </Button>
      </Show>
    </>
  );
};

const App: Component = () => {
  // If the user visits / as their first action on the site, and they're logged
  // in, redirect them to /app. But don't redirect if they navigate from /app to
  // /.
  const [isFirstRender, setIsFirstRender] = createSignal(true);

  onMount(() => {
    setIsFirstRender(false);
  });

  return (
    <Router>
      {/* We need 100% height to make the mobile sidebar work */}
      <div class="min-h-full">
        <header
          class="py-2 border-b-2 border-gray-300 mb-2"
          role="banner"
          data-cy="top-bar"
        >
          {/*
                nowrap fixes grid doing min-content and making multi-word
                buttons be only one word wide.

                With items-center, the turtle button will have zero height and
                will thus be invisible (like Imhotep). So use items-stretch to
                force it to the parent height.
                */}
          <ul class="flex gap-x-2 items-center whitespace-nowrap">
            <header class="font-semibold text-lg mr-auto">
              <A href={authnstate.session ? "/app" : "/"} class="flex">
                <img
                  class="w-6 h-6 mr-2"
                  src="/images/favicon-raw/android-chrome-512x512.png"
                  aria-hidden
                />
                ReadStuffLater
              </A>
            </header>

            <Show when={authnstate.session} fallback={<AnonymousMenu />}>
              <UserMenu />
            </Show>
          </ul>
        </header>

        <LinksStateProvider>
          <main class="flex flex-col items-center w-full">
            {/* I guess a Route must be the only and immediate child of Routes? */}
            <Routes>
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path={userSettingsUrl} component={UserSettings} />
              <Route path={`${subscribeUrl}/:url?`} component={Subscribe} />
              <Route path="/app/:nonce?" component={Links} />
              <Route path="/link-share-result" component={LinkShareResult} />
              <Route
                path="*"
                element={<Home isFirstRender={isFirstRender()} />}
              />
            </Routes>
          </main>
        </LinksStateProvider>
      </div>
    </Router>
  );
};
export default App;
