import { batch, createMemo, createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";

import { miscellaneous } from "../helpers.js";

import type { ParentComponent } from "solid-js";

import type { Link, Domain, LinksList } from "../../server/types.js";

// emoji shortcode -> domain -> link_id. Using null as an object key is
// converted to the string 'null', which we're okay with.
type DisplayLinks = Record<string, Record<string, Record<string, Link>>>;

const LinksStateContext = createContext<ReturnType<typeof initLinksState>>();

const initLinksState = () => {
  const [linksState, setLinksState] = createStore({
    _links: {} as Record<string, Link>,
    displayLinks: {} as DisplayLinks,
    // TODO: We can probably get away with only one level of filtering here, by
    // domain, then createMemo() to take care of our other filtering criteria.
    domains: { Miscellaneous: miscellaneous } as Record<string, Domain>,

    bulkUpsert({ links, domains }: LinksList) {
      //console.log("Bulk upserting");
      batch(() => {
        Object.values(domains).forEach((domain) =>
          linksState.upsertDomain(domain)
        );
        links.forEach((link) => linksState.upsertLink(link));
      });
    },

    upsertDomain(domain: Domain) {
      setLinksState("domains", domain.domain, domain);
    },
    upsertLink(link: Link) {
      // Because we insert links from both HTTP responses and websocket
      // subscriptions, there's a data race. Just be sure to only change links
      // if the content is ACTUALLY newer.
      //
      // We update from both places because we've seen in prod it's somewhat
      // routine for the websocket to just stop updating. I've had trouble
      // catching it in a debuggable state or identifying what's wrong, but my
      // suspicion is there are some circumstances where TRPC doesn't actually
      // reinitialize a disconnected subscription. It restarts the socket, but
      // not the subscription.
      const oldLink = linksState._links[link.link_id];
      if (oldLink?.updated_at > link.updated_at) return;

      setLinksState("_links", link.link_id, link);
    },

    deleteLink(linkId: string) {
      setLinksState("_links", linkId, undefined!);
    },
  });

  const emojiShortcodes = createMemo(() => {
    const linkShortcodes = Object.values(linksState._links)
      // We disregard emojis on the history page. Also, the Active Emoji
      // Picker should only show emoji for the current app view anyway.
      .filter((link) => !link.deleted_at || link.is_latest_deleted)
      .map((link) => link.emoji);

    const counts = new Map<string | null, number>();
    linkShortcodes.forEach((shortcode) =>
      counts.set(shortcode, (counts.get(shortcode) ?? 0) + 1)
    );

    let shortcodes = Array.from(counts.entries())
      .map(([shortcode, count]) => ({ shortcode, count }))
      .sort((a, b) => (a.shortcode === null ? -1 : a.count > b.count ? -1 : 1));
    // Since the app treats the N/A group as a place that always works, we need
    // to be sure that tag is always visible in the dropdown to make the UI
    // behavior make sense
    if (!shortcodes.find((s) => s.shortcode === null)) {
      shortcodes = [{ shortcode: null, count: 0 }, ...shortcodes];
    }
    return shortcodes;
  });

  return { linksState, emojiShortcodes };
};

export const LinksStateProvider: ParentComponent = (props) => {
  return (
    <LinksStateContext.Provider value={initLinksState()}>
      {props.children}
    </LinksStateContext.Provider>
  );
};

const useLinksStateContext = () => useContext(LinksStateContext)!;
export default useLinksStateContext;
