import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Show } from "solid-js";
import { A } from "@solidjs/router";

import type { Component } from "solid-js";

import authnstate from "./lib/authnstate.js";
import { getAccessExpiryHours } from "./lib/accessExpiry.js";
import { subscribeUrl } from "../client-server-shared.js";

import Bubble from "./Bubble.js";

dayjs.extend(relativeTime);

const AccessExpiryButton: Component = () => {
  const accessExpiry = getAccessExpiryHours();

  return (
    <Show
      when={accessExpiry()}
      fallback={
        <A
          class="flex items-center"
          href={subscribeUrl}
          data-cy="time-remaining"
        >
          <Bubble />
          <div>
            <p>Your access has expired!</p>
            <p class="text-xs italic">Click to subscribe</p>
          </div>
        </A>
      }
    >
      <A href={subscribeUrl}>
        <time
          class="underline"
          datetime={authnstate.session!.access_expires_at!.toISOString()}
          title={`Your trial will expire at ${new Date(
            authnstate.session!.access_expires_at!
          ).toLocaleString()}`}
          data-cy="time-remaining"
        >
          {accessExpiry()} left
          <Bubble />
        </time>
        <p class="text-xs italic" data-cy="subscribe-message">
          Click to subscribe
        </p>
      </A>
    </Show>
  );
};

export default AccessExpiryButton;
