import clsx from "clsx";

import { children, createEffect } from "solid-js";

import type { ParentComponent } from "solid-js";

// TODO: We'd like the component to be generic, accepting props matching the
// supplied tag. But I don't know how to do that.
interface ButtonProps {
  type?: string;
  style:
    | "PancakeCta"
    | "PancakePrimary"
    | "PancakeSecondary"
    | "PancakeTertiary";
}

const Button: ParentComponent<ButtonProps> = (inboundProps) => {
  const classes = clsx(
    "border rounded-md px-2 py-1",

    inboundProps.style === "PancakeCta" &&
      "border-orange-600 bg-orange-700 text-white",

    inboundProps.style === "PancakePrimary" &&
      "border-orange-400 bg-orange-100",

    inboundProps.style === "PancakeSecondary" && "bg-orange-50",

    inboundProps.style === "PancakeTertiary" && "border-gray-300 text-gray-600"
  );

  const childrenMemo = children(() => inboundProps.children);
  createEffect(() => {
    const children = childrenMemo
      .toArray()
      .filter((child): child is HTMLElement => child instanceof HTMLElement);
    children.forEach((child) => child.classList.add(...classes.split(" ")));
  });

  return childrenMemo();
};

export default Button;
